import {useLocation} from "react-router-dom";
import {getPresentRoute} from "../lib/RouteHelper";

const CustomLink = ({href, className, linkId, children, redirect= true}) => {
    const location = useLocation();
    const clickEventHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { route, _routeParams } = getPresentRoute(location.pathname)
        if(redirect) {
            if(href.startsWith('http')) { window.open(href); }
            else { window.location.href = `${window.location.origin}/${href}`; }
        }
        return false;
    }

    const getHtml = () => {
        return <span id={linkId} onClick={clickEventHandler} className={`${className} a-span`.trim()}>{children}</span>
    }

    return getHtml();
}

export default CustomLink;