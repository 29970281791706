
import {useLocation} from "react-router-dom";
import {getPresentRoute} from "../lib/RouteHelper";

const CustomButtonLink = ({clickEvent, className, linkId, children}) => {
    const location = useLocation();
    const clickEventHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        clickEvent(e);
    }

    const getHtml = () => {
        return <span id={linkId} onClick={clickEventHandler} className={`${className} a-span`.trim()}>{children}</span>;
    }

    return getHtml();
}

export default CustomButtonLink;