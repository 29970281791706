import CustomLink from "./CustomLink";
import React from "react";
import CustomButton from "./CustomButton";
import { Modal } from "bootstrap";
import { subscribe } from "../lib/event";
import axios from "axios";
import useRazorpay from "react-razorpay";
import { SessionUser } from "../lib/SessionUser";

const RsvpBar = ({
  mode,
  ticketCount,
  price,
  eventId,
  availableTickets,
  userParams,
  showCheckoutError,
  eventSlotId,
  event,
}) => {
  const [Razorpay] = useRazorpay();

  const getPriceDetails = () => {
    if (mode === "rsvp" && event.booking_details.rsvp_enabled) {
      return (
        <div className="me-auto d-flex price-details">
          <span>Ticket Price</span>
          <h3
            dangerouslySetInnerHTML={{
              __html: isFreeEvent() ? `Free` : `&#x20B9; ${price}/person`,
            }}
          />
        </div>
      );
    } else if (mode === "checkout") {
      if (isFreeEvent()) {
        return (
          <div className="me-auto d-flex price-details">
            <span>Total Cost</span>
            <h3>Free</h3>
          </div>
        );
      }
      return (
        <div className="me-auto d-flex price-details">
          <span>Total Cost</span>
          <h3>&#x20B9; {price}</h3>
        </div>
      );
    }
  };

  const rsvp = () => {
    const checkoutLink = `social_experience/${eventId}/checkout`;
    window.location.href = `${window.location.origin}/${checkoutLink}`;
    // if(!user) {
    //     const modal = new Modal('#loginModal');
    //     // TODO: [Hack] need to remove this when we move to a full page implementation
    //     document.getElementById('loginModalLoginReasonText').innerHTML = 'You need to login to RSVP for this event';
    //     modal.show();
    //     subscribe('userLoggedIn',() => {
    //         window.location.href = `${window.location.origin}/${checkoutLink}`;
    //     });
    // } else {
    //     window.location.href = `${window.location.origin}/${checkoutLink}`;
    // }
  };

  const openEvent = () => {
    window.open(event.shortened_link);
  };

  const isFreeEvent = () => {
    return price <= 0;
  };

  const payNow = async () => {
    const showErrorAlert = (message) => {
      document.getElementById("errorScreenText").innerHTML = message;
      document
        .getElementById("mainContainer")
        .classList.add("error-alert-open");
      setTimeout(() => {
        document.getElementById("errorScreenText").innerHTML = "";
        document
          .getElementById("mainContainer")
          .classList.remove("error-alert-open");
      }, 3000);
    };

    const errorHandler = () => {
      showErrorAlert("Event RSVP not successful");
      showCheckoutError(true);
    };

    const handleSuccessfulBooking = async (data) => {
      document.getElementById("successScreenText").innerHTML =
        "Event RSVP successful";
      document
        .getElementById("mainContainer")
        .classList.add("success-alert-open");

      setTimeout(async () => {
        try {
          const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
          });
          const { data: profileData } = await axiosInstance.get(
            "/user/profile.json",
            { headers: { Authorization: SessionUser.userToken() } }
          );
          SessionUser.updateProfile(profileData.data);

          document.getElementById("successScreenText").innerHTML = "";
          document
            .getElementById("mainContainer")
            .classList.remove("success-alert-open");
          window.location.href = `${window.location.origin}/my_social_experience/${data.order.order_id}`;
        } catch (error) {
          errorHandler();
        }
      }, 3000);
    };

    const handlePaymentSuccess = async (order, response) => {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
      const paymentConfirmation = {
        payment_id: response.razorpay_payment_id,
        order_id: response.razorpay_order_id,
        signature: response.razorpay_signature,
      };

      const { data } = await axiosInstance.patch(
        `/user/bookings/${order.order_id}/pay.json`,
        paymentConfirmation,
        { headers: { Authorization: SessionUser.userToken() } }
      );

      if (data.success) {
        handleSuccessfulBooking(data.data);
      } else {
        errorHandler();
      }
    };

    const initializeRazorpay = (order, user, event) => {

      const options = {
        key: order.payment_config.razorpay_key,
        amount: `${order.amount * 100}`,
        currency: order.payment_config.currency,
        name: order.payment_config.company_name,
        description: event.description,
        image: order.payment_config.image_url,
        order_id: order.payment.order_id,
        handler: async (response) => handlePaymentSuccess(order, response),
        modal: {
          ondismiss: errorHandler,
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.mobile,
        },
        theme: { color: order.payment_config.theme },
      };

      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", errorHandler);
      rzp1.open();
    };

    window.scrollTo(0, 0);

    try {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
      const orderParams = {
        total_seats: ticketCount,
        user_name: userParams.name,
        user_email: userParams.email,
        user_mobile: userParams.mobile,
        experience_id: eventId,
        experience_slot_id: eventSlotId,
      };
      console.log('Making request to:', `${process.env.REACT_APP_API_URL}/user/bookings.json`);
      const { data } = await axiosInstance.post(
        "/user/bookings.json",
        orderParams,
        { headers: { Authorization: SessionUser.userToken(), 'Content-Type': 'application/json' } }
      );

      if (data.success) {
        const { order, event } = data.data;
        if (price > 0) {
          if (order.user.mobile && order.user.mobile.length === 10) {
            initializeRazorpay(order, order.user, event);
          } else {
            showCheckoutError(true);
            // document.getElementById('errorScreenText').innerHTML = 'Please enter a valid 10 digit mobile number';
            // document.getElementById('mainContainer').classList.add('error-alert-open');
            // setTimeout(() => {
            //   document.getElementById('errorScreenText').innerHTML = '';
            //   document.getElementById('mainContainer').classList.remove('error-alert-open');
            //   showCheckoutError(false);
            // }, 3000);
          }
        } else {
          handleSuccessfulBooking(data.data);
        }
      } else {
        errorHandler();
      }
    } catch (error) {
      console.error('Full error:', error);
      console.error('Response:', error.response);
      // Detailed error logging
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        console.error('Response Error:', {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers
        });
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request Error:', error.request);
      } else {
        // Something happened in setting up the request
        console.error('Error:', error.message);
      }
      throw error;
    }
  };

  const getRsvpAction = () => {
    if (event.booking_details.rsvp_enabled) {
      if (mode === "rsvp") {
        if (availableTickets > 0) {
          return (
            <CustomButton
              buttonId="RsvpLink"
              className="ms-auto d-flex btn primary-btn"
              clickEvent={rsvp}
            >
              RSVP
            </CustomButton>
          );
        } else {
          return (
            <span className="ms-auto d-flex btn primary-btn disabled">
              Sold Out
            </span>
          );
        }
      } else if (mode === "checkout") {
        return (
          <CustomButton
            buttonId="PayNowButton"
            clickEvent={payNow}
            className="btn primary-btn"
          >
            {isFreeEvent() ? "RSVP" : "Pay Now"}
          </CustomButton>
        );
      }
    } else {
      return (
        <CustomButton
          buttonId="RsvpLink"
          className="ms-auto d-flex btn primary-btn"
          clickEvent={openEvent}
        >
          Know More Details
        </CustomButton>
      );
    }
  };

  return (
    <div
      className={`d-flex rsvp-details ${
        event.booking_details.rsvp_enabled ? "" : "non-hosted"
      }`}
      //   style={{ position: "sticky", bottom: "0" }}
    >
      {event.booking_details.rsvp_enabled && getPriceDetails()}
      {getRsvpAction()}
    </div>
  );
};

export default RsvpBar;
