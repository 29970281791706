
import { useLocation } from "react-router-dom";
import { getPresentRoute } from "../lib/RouteHelper";

const CustomButton = ({
  className,
  buttonId,
  children,
  clickEvent,
  otherProps,
  otherStyle,
}) => {
  const location = useLocation();
  const clickEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { route, _routeParams } = getPresentRoute(location.pathname);
    clickEvent(e);
  };

  return (
    <button
      className={className}
      onClick={clickEventHandler}
      style={otherStyle}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default CustomButton;
